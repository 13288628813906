<template>
    <b-container class="c-page">
        <div class="c-page__title" >
            <div>
                <h1>Aktuality</h1>
            </div>
            <div class="c-page__controls">
                <b-icon class="clickable" icon="plus-square-fill" variant="info"
                @click="addItem()"></b-icon>
            </div>
        </div>
        <table class="table c-page__table">
            <thead>
                <tr>
                    <th><b-icon class="clickable" icon="arrow-down-up" title="Pořadí" ></b-icon></th>
                    <th class="c-page__table-control-col">#</th>
                    <th>Název</th>
                    <th></th>
                </tr>
            </thead>
            <draggable v-model="posts"
                       group="test"
                       @change="sort"
                       tag="tbody"
                       handle=".sortable">
                <tr :key="item.id" v-for="item in posts">
                    <td class="c-page__table-control-col sortable">
                        <b-icon class="clickable" icon="justify" variant="info" ></b-icon>
                    </td>
                    <td class="c-page__table-control-col">
                        <b-icon v-if="item.visible" icon="eye-fill" variant="info" ></b-icon>
                        <b-icon v-else icon="eye-slash" variant="info" ></b-icon>
                    </td>
                    <td>
                        {{item.name_cs}}
                    </td>
                    <td class="text-right c-page__table-edit">
                        <router-link :to="{name: 'testEdit', params: {id: item.id}}">
                            <b-icon class="clickable" icon="pencil-square" variant="info" ></b-icon>
                        </router-link>
                    </td>
                </tr>
            </draggable>

        </table>
        <router-view @loadData="loadData()"></router-view>
    </b-container>

</template>

<script>
    import draggable from 'vuedraggable';

    export default {
        name: 'test',
        components: {
            draggable,
        },
        data() {
            return {
                posts: []
            }
        },
        methods: {
            async loadData() {
                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/test/list.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        method: 'GET'
                    });

                    this.posts = response.data;

                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', 'No data');
                }

            },
            async addItem() {

                try {
                    const response = await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/test/add-item.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        method: 'POST'
                    });

                    this.$router.push( {name: 'testEdit', params: {id: response.data.id}} );



                } catch (error) {
                    this.loadData();
                    console.log("error", error);
                }

            },

            async sort(evt) {

                const newIndex = evt.moved.newIndex;
                const oldIndex = evt.moved.oldIndex;

                try {
                    await this.$http({
                        url: process.env.VUE_APP_SERVER + 'api/test/sort.php',
                        headers: { 'content-type': 'application/x-www-form-urlencoded' },
                        method: 'POST',
                        data: {
                            'new_index': newIndex,
                            'old_index': oldIndex
                        },
                    });

                    this.loadData();
                    this.$eventHub.$emit('openMessageController', 'success', '✓');

                } catch (error) {
                    console.log("error", error);
                    this.$eventHub.$emit('openMessageController', 'error', 'No data');
                }
            }
        },
        computed: {

        },
        mounted () {
            this.loadData();

        }
    }
</script>

<style scoped lang="scss">

</style>
